import axios from 'axios'

export default {
  namespaced: true,

  state: {
    robots: [],
    robot: null,
    isLoading: false,
    createdData: null,
    updatedData: null,
    isDeleting: false,
    deletedData: null,
    msg: null,
    msg_color: null
  },

  getters: {
    robotList: robot => robot.robots,
    robot: robot => robot.robot,
    isLoading: robot => robot.isLoading,
    createdData: robot => robot.createdData,
    updatedData: robot => robot.updatedData,
    isDeleting: robot => robot.isDeleting,
    deletedData: robot => robot.deletedData,
    msg: robot => robot.msg,
    msg_color: robot => robot.msg_color
  },

  actions: {
    async fetchAllRobots ({ commit }, config = null) {
      let page = 1
      let search = ''
      let order = 'asc'
      let orderBy = 'id'
      let perPage = 15

      if (config !== null) {
        page = config.page
        search = config.search
        order = config.order
        orderBy = config.orderBy
        perPage = config.perPage
      }

      let url = `robots?page=${page}`
      if (search === '') {
        commit('setRobotIsLoading', true)
        url = `${url}&orderby=${orderBy}&order=${order}&perpage=${perPage}`
      } else {
        url = `${url}&orderby=${orderBy}&order=${order}&search=${search}&perpage=${perPage}`
      }
      await axios.get(url)
        .then(response => {
          const robots = response.data
          commit('setRobots', robots)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async fetchDetailRobot ({ commit }, id) {
      commit('setRobotIsLoading', true)
      await axios.get(`robots/${id}`)
        .then(res => {
          commit('setRobotDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async storeRobot ({ commit }, robot) {
      commit('setRobotIsLoading', true)
      await axios.post('robots', robot)
        .then(res => {
          commit('saveNewRobots', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updateRobot ({ commit }, robot) {
      commit('setRobotIsLoading', true)
      await axios.put(`robots/${robot.id}`, robot)
        .then(res => {
          commit('saveUpdatedRobot', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async deleteRobot ({ commit }, id) {
      commit('setRobotIsDeleting', true)
      await axios.delete(`robots/${id}`)
        .then(res => {
          commit('setDeleteRobot', id)
          commit('setRobotIsDeleting', false)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
          commit('setRobotIsDeleting', false)
        })
    },

    updateRobotInput ({ commit }, e) {
      commit('setRobotDetailInput', e)
    },

    clearRobot ({ commit }) {
      const clearRobot = {'name': null, 'email': null, 'id': null, 'avatar': null, 'company_id': null}
      commit('setRobotDetail', clearRobot)
    },

    closeMessage ({ commit }) {
      commit('setRobotIsLoading', false)
    }
  },

  mutations: {
    setRobots: (state, robots) => {
      state.robots = robots
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setRobotDetail: (state, robot) => {
      state.robot = robot
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setDeleteRobot: (state, id) => {
      state.robots.data = state.robots.data.filter(field => field.id !== id)
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setRobotDetailInput: (state, e) => {
      const robot = state.robot
      robot[e.target.name] = e.target.value
      state.robot = robot
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveNewRobots: (state, robot) => {
      state.robots.data.unshift(robot)
      state.createdData = robot
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveUpdatedRobot: (state, robot) => {
      const idx = state.robots.data.findIndex(o => o.id === robot.id)
      if (idx >= 0) {
        state.robots.data[idx] = robot
      }
      state.updatedData = robot
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setRobotIsLoading (state, isLoading) {
      state.isLoading = isLoading
      state.msg = null
      state.msg_color = null
    },

    setRobotIsDeleting (state, isDeleting) {
      state.isDeleting = isDeleting
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMsg(state, data) {
      const xMessage = []
      let erro
      if (typeof (data) === 'object') {
        for (erro in data) {
          xMessage.push(data[erro][0])
        }
        state.msg = xMessage
      } else {
        state.msg = data
      }
      state.isLoading = false
    },

    setMsg_color(state, data) {
      state.msg_color = data
      state.isLoading = false
    }

  }
}
