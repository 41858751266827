export default {
  setToken (token) {
    localStorage.setItem('TOKEN_NAME', token);
  },
  setAvatar (avatar) {
    localStorage.setItem('AVATAR_NAME', avatar);
  },
  setBusiness (business) {
    localStorage.setItem('BUSINESS_NAME', business);
  },
  setUserInfo (user) {
    localStorage.setItem('USER_INFO', user);
  },



  getToken () {
    return localStorage.getItem('TOKEN_NAME');
  },
  getAvatar () {
    return localStorage.getItem('AVATAR_NAME');
  },
  getBusiness () {
    return localStorage.getItem('BUSINESS_NAME');
  },
  getUserInfo () {
    return localStorage.getItem('USER_INFO');
  },


  deleteToken () {
    localStorage.removeItem('AVATAR_NAME');
    localStorage.removeItem('TOKEN_NAME');
    localStorage.removeItem('BUSINESS_NAME');
    localStorage.removeItem('USER_INFO');
  }

}
