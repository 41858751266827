export default {
  namespaced: true,

  state: {
    findCnpj: [],

  },

  getters: {
    findCnpj: findCnpj => findCnpj.findCnpj,
  },

  actions: {
    async searchCnpj({ commit }, findCnpj) {
      try {
        let cnpj = findCnpj.replace(/[^0-9]/g,'');
        commit('setFindCnpj', {})
        const response = await fetch(
          `https://publica.cnpj.ws/cnpj/${cnpj}`
        );
        const info = await response.json();

        if (info.erro) {
          commit('setFindCnpj', {})
        } else {
          commit('setFindCnpj', info)
        }
        return true;
      } catch {
        return false;
      }
    },
  },
  mutations: {
    setFindCnpj(state, info) {
      state.findCnpj = info

    },
  },
}
