import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import error404 from '../views/pages/error404.vue'
import store from '../store';
import Guard from '../service/middleware'

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
    //dashboard
    {   path: '/', 
        name: 'Home', 
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: Home },

    {
        path: '/app/companies',
        name: 'companies',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/app/companies.vue'),
    },
    {
        path: '/app/messages',
        name: 'messages',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/app/messages.vue'),
    },
    {
        path: '/app/robots',
        name: 'robots',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "components-tabs" */ '../views/app/robots.vue'),
    },

    //pages
    {
        path: '/pages/error403',
        name: 'error403',
        component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error403.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error404',
        name: 'error404',
        component: 'error404',
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error500',
        name: 'error500',
        component: () => import(/* webpackChunkName: "pages-error500" */ '../views/pages/error500.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/pages/error503',
        name: 'error503',
        component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error503.vue'),
        meta: { layout: 'auth' },
    },
    //auth
    {
        path: '/auth/login',
        name: 'Login',
        beforeEnter: Guard.redirectIfAuthenticated,
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/lockscreen',
        name: 'lockscreen',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/auth/lockscreen.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/auth/pass-recovery',
        name: 'pass-recovery',
        beforeEnter: Guard.redirectIfAuthenticated,
        component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
        meta: { layout: 'auth' },
    },
    {
        path: '/verify-email',
        name: 'VerifyEmail',
        component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/VerifyEmail.vue'),
        meta: { layout: 'auth' },
    },

    //users
    {
        path: '/users',
        name: 'users',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/index.vue'),
    },

    {
        path: '/users/profile',
        name: 'profile',
        beforeEnter: Guard.redirectIfNotAuthenticated,
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue'),
    },
    { path: '/:pathMatch(.*)*', component: error404, meta: { layout: 'auth' }, },
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
