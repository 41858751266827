export default {
  namespaced: true,

  state: {
    zipCode: [],

  },

  getters: {
    zipCode: zipCode => zipCode.zipCode,
  },

  actions: {
    async searchZipCode({ commit }, zipCode) {
      try {
        commit('setZipCode', {})
        const response = await fetch(
          `https://viacep.com.br/ws/${zipCode}/json/`
        );
        const info = await response.json();

        if (info.erro) {
          commit('setZipCode', {})
        } else {
          commit('setZipCode', info)
        }
        return true;
      } catch {
        return false;
      }
    },
  },
  mutations: {
    setZipCode(state, info) {
      state.zipCode = info

    },
  },
}
