import axios from 'axios'
import router from '../router'
import Cookie from '../service/cookie'

export default {
  namespaced: true,

  state: {
    loggedInUser: [],
    loading: false,
    msg: null,
    msg_color: null
  },

  getters: {
    loggedInUser: state => state.loggedInUser,
    msg: state => state.msg,
    msg_color: state => state.msg_color
  },

  mutations: {
    setUser (state, data) {
      state.loggedInUser = data
      state.loading = true
      state.msg = null
      state.msg_color = null
    },

    setLogout (state) {
      state.loggedInUser = []
      state.loading = false
      state.msg = null
      state.msg_color = null
    },
    setLoading (state, data) {
      state.loading = data
      state.msg = null
      state.msg_color = null
    },
    setMsg(state, data) {
      const xMessage = []
      let erro
      if (typeof (data) === 'object') {
        for (erro in data) {
          xMessage.push(data[erro][0])
        }
        state.msg = xMessage
      } else {
        state.msg = data
      }
      state.isLoading = false
    },
    setMsg_color(state, data) {
      state.msg_color = data
      state.loading = false
    },

    clearMsg (state) {
      state.msg = null
      state.msg_color = null
      state.loading = false
    }
  },

  actions: {
    show ({ commit }) {
      commit('clearMsg')
    },

    login ({ commit }, payload) {
      commit('clearMsg')
      commit('setLoading', true)
      axios.post('login', payload).then((response) => {
        const token = `${response.data.token_type} ${response.data.access_token}`
        Cookie.setToken(token)
        Cookie.setAvatar(response.data.data.avatar)
        Cookie.setBusiness(response.data.data.company.business)
        Cookie.setUserInfo(response.data.data.id)
        const dataUser = response.data.data
        commit('setUser', dataUser)
        router.push('/')
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        Cookie.deleteToken()
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    },

    async me ({ commit }) {
      commit('clearMsg')
      commit('setLoading', true)
      await axios.get('me').then((response) => {
        const dataUser = response.data.data
        commit('setUser', dataUser)
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    },

   logout ({ commit }) {
      console.log('entrou logout')
      commit('setLoading', true)
      commit('clearMsg')
      axios.post('logout').then((response) => {
        console.log(response)
        const logout = `${response.data.status}`
        if (logout === 'success') {
          Cookie.deleteToken()
          location.reload()
          //router.push({ name: `Login` });
        }
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    },

    forgotPassword ({ commit }, payload) {
      commit('clearMsg')
      commit('setLoading', true)
      axios.post('forgot-password', payload).then((response) => {
        commit('setMsg', 'Enviamos um e-mail com instruções de recuperação de senha.')
        commit('setMsg_color', 'green')
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    },

    verifyEmail ({ commit }, payload) {
      commit('clearMsg')
      commit('setLoading', true)
      axios.post('verify-email', payload).then((response) => {
        commit('setMsg', 'E-mail verificado com sucesso!')
        commit('setMsg_color', 'green')
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    },

    resetPassword ({ commit }, payload) {
      commit('clearMsg')
      commit('setLoading', true)
      axios.post('reset-password', payload).then((response) => {
        commit('setMsg', 'Senha alterada com sucesso!')
        commit('setMsg_color', 'green')
      }).catch((e) => {
        const msgMsg = e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
        commit('setMsg', msgMsg)
        commit('setMsg_color', 'red')
      })
    }
  }
}
