import { useHead } from '@vueuse/head';
import { unref, computed } from 'vue';

let siteTitle = 'Painel Dash';
let separator = '|';

export const usePageTitle = (pageTitle) =>
    useHead(
        computed(() => ({
            title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
        }))
    );

export const useMeta = (data) => {
    return useHead({ ...data, title: `Painel Dash | ${data.title}` });
};

export const storagePath = 'https://painel.sigmazap.com/api/public/storage/';

