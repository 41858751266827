import axios from 'axios'
import Cookie from '../service/cookie'

export default {
  namespaced: true,

  state: {
    messages: [],
    message: null,
    isLoading: false,
    createdData: null,
    updatedData: null,
    isDeleting: false,
    deletedData: null,
    msg: null,
    msg_color: null
  },

  getters: {
    messageList: message => message.messages,
    message: message => message.message,
    isLoading: message => message.isLoading,
    createdData: message => message.createdData,
    updatedData: message => message.updatedData,
    isDeleting: message => message.isDeleting,
    deletedData: message => message.deletedData,
    msg: message => message.msg,
    msg_color: message => message.msg_color
  },

  actions: {
    async fetchAllMessages ({ commit }, config = null) {
      let page = 1
      let search = ''
      let order = 'asc'
      let orderBy = 'id'
      let perPage = 1000

      if (config !== null) {
        page = config.page
        search = config.search
        order = config.order
        orderBy = config.orderBy
        perPage = config.perPage
      }

      let negocio = Cookie.getBusiness()
      let url = `messages?page=${page}`
      commit('setMessageIsLoading', true)
      if (search === '') {
        url = `${url}&orderby=${orderBy}&order=${order}&perpage=${perPage}&business=${negocio}`
      } else {
        url = `${url}&orderby=${orderBy}&order=${order}&search=${search}&perpage=${perPage}&business=${negocio}`
      }

      

      await axios.get(url)
        .then(response => {
          const messages = response.data
          commit('setMessages', messages)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async fetchDetailMessage ({ commit }, id) {
      commit('setMessageIsLoading', true)
      await axios.get(`messages/${id}`)
        .then(res => {
          commit('setMessageDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async storeMessage ({ commit }, message) {
      commit('setMessageIsLoading', true)
      await axios.post('messages', message)
        .then(res => {
          commit('saveNewMessages', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updateMessage ({ commit }, message) {
      commit('setMessageIsLoading', true)
      await axios.put(`messages/${message.id}`, message)
        .then(res => {
          commit('saveUpdatedMessage', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async deleteMessage ({ commit }, id) {
      commit('setMessageIsDeleting', true)
      await axios.delete(`messages/${id}`)
        .then(res => {
          commit('setDeleteMessage', id)
          commit('setMessageIsDeleting', false)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
          commit('setMessageIsDeleting', false)
        })
    },

    updateMessageInput ({ commit }, e) {
      commit('setMessageDetailInput', e)
    },

    clearMessage ({ commit }) {
      const limpo = { 'name': null, 
                      'email': null, 
                      'id': null, 
                      'cnpj': null, 
                      'business': null, 
                      'cep': null, 
                      'address': null, 
                      'complement': null, 
                      'number': null, 
                      'neighborhood': null,
                      'city': null,
                      'state': null,
                      'cell_phone': null,
                      'phone': null,
                      'messages_contracted': null}
      commit('setMessageDetail', limpo)
    },

    closeMessage ({ commit }) {
      commit('setMessageIsLoading', false)
    }
  },

  mutations: {
    setMessages: (state, messages) => {
      state.messages = messages
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMessageDetail: (state, message) => {
      state.message = message
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setDeleteMessage: (state, id) => {
      state.messages.data = state.messages.data.filter(field => field.id !== id)
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMessageDetailInput: (state, e) => {
      const message = state.message
      message[e.target.name] = e.target.value
      state.message = message
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveNewMessages: (state, message) => {
      state.messages.data.unshift(message)
      state.createdData = message
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveUpdatedMessage: (state, message) => {
      const idx = state.messages.data.findIndex(o => o.id === message.id)
      if (idx >= 0) {
        state.messages.data[idx] = message
      }
      state.updatedData = message
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMessageIsLoading (state, isLoading) {
      state.isLoading = isLoading
      state.msg = null
      state.msg_color = null
    },

    setMessageIsDeleting (state, isDeleting) {
      state.isDeleting = isDeleting
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMsg(state, data) {
      const xMessage = []
      let erro
      if (typeof (data) === 'object') {
        for (erro in data) {
          xMessage.push(data[erro][0])
        }
        state.msg = xMessage
      } else {
        state.msg = data
      }
      state.isLoading = false
    },

    setMsg_color(state, data) {
      state.msg_color = data
      state.isLoading = false
    }

  }
}
