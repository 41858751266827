import axios from 'axios'

export default {
  namespaced: true,

  state: {
    companies: [],
    company: null,
    isLoading: false,
    createdData: null,
    updatedData: null,
    isDeleting: false,
    deletedData: null,
    msg: null,
    msg_color: null
  },

  getters: {
    companyList: company => company.companies,
    company: company => company.company,
    isLoading: company => company.isLoading,
    createdData: company => company.createdData,
    updatedData: company => company.updatedData,
    isDeleting: company => company.isDeleting,
    deletedData: company => company.deletedData,
    msg: company => company.msg,
    msg_color: company => company.msg_color
  },

  actions: {
    async fetchAllCompanies ({ commit }, config = null) {
      let page = 1
      let search = ''
      let order = 'asc'
      let orderBy = 'id'
      let perPage = 1000

      if (config !== null) {
        page = config.page
        search = config.search
        order = config.order
        orderBy = config.orderBy
        perPage = config.perPage
      }

      let url = `companies?page=${page}`
      if (search === '') {
        commit('setCompanyIsLoading', true)
        url = `${url}&orderby=${orderBy}&order=${order}&perpage=${perPage}`
      } else {
        url = `${url}&orderby=${orderBy}&order=${order}&search=${search}&perpage=${perPage}`
      }

      await axios.get(url)
        .then(response => {
          const companies = response.data
          commit('setCompanies', companies)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async fetchDetailCompany ({ commit }, id) {
      commit('setCompanyIsLoading', true)
      await axios.get(`companies/${id}`)
        .then(res => {
          commit('setCompanyDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async storeCompany ({ commit }, company) {
      commit('setCompanyIsLoading', true)
      await axios.post('companies', company)
        .then(res => {
          commit('saveNewCompanies', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updateCompany ({ commit }, company) {
      commit('setCompanyIsLoading', true)
      await axios.put(`companies/${company.id}`, company)
        .then(res => {
          commit('saveUpdatedCompany', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async deleteCompany ({ commit }, id) {
      commit('setCompanyIsDeleting', true)
      await axios.delete(`companies/${id}`)
        .then(res => {
          commit('setDeleteCompany', id)
          commit('setCompanyIsDeleting', false)
        }).catch(err => {
          const msgMsg = err?.response?.data?.errors || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
          commit('setCompanyIsDeleting', false)
        })
    },

    updateCompanyInput ({ commit }, e) {
      commit('setCompanyDetailInput', e)
    },

    clearCompany ({ commit }) {
      const limpo = { 'name': null, 
                      'email': null, 
                      'id': null, 
                      'cnpj': null, 
                      'business': null, 
                      'cep': null, 
                      'address': null, 
                      'complement': null, 
                      'number': null, 
                      'neighborhood': null,
                      'city': null,
                      'state': null,
                      'cell_phone': null,
                      'phone': null,
                      'messages_contracted': null}
      commit('setCompanyDetail', limpo)
    },

    closeMessage ({ commit }) {
      commit('setCompanyIsLoading', false)
    }
  },

  mutations: {
    setCompanies: (state, companies) => {
      state.companies = companies
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setCompanyDetail: (state, company) => {
      state.company = company
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setDeleteCompany: (state, id) => {
      state.companies.data = state.companies.data.filter(field => field.id !== id)
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setCompanyDetailInput: (state, e) => {
      const company = state.company
      company[e.target.name] = e.target.value
      state.company = company
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveNewCompanies: (state, company) => {
      state.companies.data.unshift(company)
      state.createdData = company
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveUpdatedCompany: (state, company) => {
      const idx = state.companies.data.findIndex(o => o.id === company.id)
      if (idx >= 0) {
        state.companies.data[idx] = company
      }
      state.updatedData = company
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setCompanyIsLoading (state, isLoading) {
      state.isLoading = isLoading
      state.msg = null
      state.msg_color = null
    },

    setCompanyIsDeleting (state, isDeleting) {
      state.isDeleting = isDeleting
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMsg(state, data) {
      const xMessage = []
      let erro
      if (typeof (data) === 'object') {
        for (erro in data) {
          xMessage.push(data[erro][0])
        }
        state.msg = xMessage
      } else {
        state.msg = data
      }
      state.isLoading = false
    },

    setMsg_color(state, data) {
      state.msg_color = data
      state.isLoading = false
    }

  }
}
