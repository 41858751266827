<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
      <div class="shadow-bottom"></div>

      <perfect-scrollbar class="list-unstyled menu-categories" tag="ul"
        :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">

        <li class="menu">
          <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-home">
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>{{ $t('dashboard') }}</span>
            </div>
          </router-link>
        </li>

        <li v-if="isAdmin" class="menu">
          <router-link to="/users" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-users">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span>{{ $t('users') }}</span>
            </div>
          </router-link>
        </li>

        <li v-if="isAdmin" class="menu">
          <router-link to="/app/companies" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-airplay">
                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                <polygon points="12 15 17 21 7 21 12 15"></polygon>
              </svg>
              <span>Empresas</span>
            </div>
          </router-link>
        </li>

        <li class="menu">
          <router-link to="/app/messages" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-layers">
                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                <polyline points="2 17 12 22 22 17"></polyline>
                <polyline points="2 12 12 17 22 12"></polyline>
              </svg>
              <span>Mensagens</span>
            </div>
          </router-link>
        </li>

        <li v-if="isAdmin" class="menu">
          <router-link to="/app/robots" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-cpu">
                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                <rect x="9" y="9" width="6" height="6"></rect>
                <line x1="9" y1="1" x2="9" y2="4"></line>
                <line x1="15" y1="1" x2="15" y2="4"></line>
                <line x1="9" y1="20" x2="9" y2="23"></line>
                <line x1="15" y1="20" x2="15" y2="23"></line>
                <line x1="20" y1="9" x2="23" y2="9"></line>
                <line x1="20" y1="14" x2="23" y2="14"></line>
                <line x1="1" y1="9" x2="4" y2="9"></line>
                <line x1="1" y1="14" x2="4" y2="14"></line>
              </svg>
              <span>Robô</span>
            </div>
          </router-link>
        </li>

      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Cookie from '../../service/cookie'
const store = useStore();

const menu_collapse = ref('dashboard');

const isAdmin = Cookie.getBusiness() == 'SIGMA'

onMounted(() => {
  const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');


  if (selector) {
    const ul = selector.closest('ul.collapse');
    if (ul) {
      let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
      if (ele) {
        ele = ele[0];
        setTimeout(() => {
          ele.click();
        });
      }
    } else {
      selector.click();
    }
  }
});

const toggleMobileMenu = () => {
  if (window.innerWidth < 991) {
    store.commit('toggleSideBar', !store.state.is_show_sidebar);
  }
};
</script>
