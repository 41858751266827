import Cookie from '../service/cookie'

const restricted = ['/users','/app/companies','/app/robots']

export default {

  async redirectIfNotAuthenticated(to, from, next) {
    const token = Cookie.getToken()
    const isAdmin = Cookie.getBusiness() == 'SIGMA'
    if (token) {
      if ((restricted.indexOf(to.path) > -1) && !(isAdmin)) {
        next({ name: 'error403' })
      } else {
        next()
      }
    } else {
      Cookie.deleteToken()
      next({ name: 'Login' })
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'warning',
        title: 'Sua seção expirou, faça Login novamente !',
      })
    }
  },

  async redirectIfAuthenticated(to, from, next) {
    const token = Cookie.getToken()
    let n
    if (token) {
      n = { name: 'Home' }
      next(n)
    } else {
      next()
    }
    
  },

}
