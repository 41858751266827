import router from '../router'
import axios from 'axios'
import Cookie from '../service/cookie'

/*axios.defaults.baseURL = 'http://paineldash2.local/api/v1/'*/
axios.defaults.baseURL = 'https://painel.sigmazap.com/api/public/api/v1/'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(function (config) {
  const token = Cookie.getToken()

  if (token) {
    config.headers['Authorization'] = token
  }
  return config
})

axios.interceptors.response.use(
  response => {
    const res = response

    // if the custom code is not 20000, it is judged as an error.
    if (res.message === 'Unauthenticated.') {
      // 50008: Illegal token; 50012: Other clients logged in; 50005: Token expired;
      Cookie.deleteToken()
      router.push({ name: 'Login' })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error.response.data.message === 'Unauthenticated.') {
      // 50008: Illegal token; 50012: Other clients logged in; 50005: Token expired;
      Cookie.deleteToken()
      router.push({ name: 'Login' })

      return Promise.reject(new Error(error.message || 'Error'))
    } else if (error.response.data.message === 'Access permission not released for this user.') {
      // 50008: Illegal token; 50012: Other clients logged in; 50005: Token expired;
      router.push({ name: 'error403' })
      return Promise.reject(new Error(error.message || 'Error'))
    } else {
      return Promise.reject(error)
    }
  }
)

export default {
  axios
}
