import axios from 'axios'
import Cookie from '../service/cookie'

export default {
  namespaced: true,

  state: {
    users: [],
    user: null,
    isLoading: false,
    createdData: null,
    updatedData: null,
    isDeleting: false,
    deletedData: null,
    msg: null,
    msg_color: null
  },

  getters: {
    userList: user => user.users,
    user: user => user.user,
    isLoading: user => user.isLoading,
    createdData: user => user.createdData,
    updatedData: user => user.updatedData,
    isDeleting: user => user.isDeleting,
    deletedData: user => user.deletedData,
    msg: user => user.msg,
    msg_color: user => user.msg_color
  },

  actions: {
    async fetchAllUsers ({ commit }, config = null) {
      let page = 1
      let search = ''
      let order = 'asc'
      let orderBy = 'id'
      let perPage = 15

      if (config !== null) {
        page = config.page
        search = config.search
        order = config.order
        orderBy = config.orderBy
        perPage = config.perPage
      }

      let url = `users?page=${page}`
      if (search === '') {
        commit('setUserIsLoading', true)
        url = `${url}&orderby=${orderBy}&order=${order}&perpage=${perPage}`
      } else {
        url = `${url}&orderby=${orderBy}&order=${order}&search=${search}&perpage=${perPage}`
      }
      await axios.get(url)
        .then(response => {
          const users = response.data
          commit('setUsers', users)
        }).catch(err => {
          const msgMsg = err?.response?.data?.error || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async fetchDetailUser ({ commit }, id) {
      commit('setUserIsLoading', true)
      await axios.get(`users/${id}`)
        .then(res => {
          commit('setUserDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.error || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async storeUser ({ commit }, user) {
      commit('setUserIsLoading', true)
      await axios.post('users', user)
        .then(res => {
          commit('saveNewUsers', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.error || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updateUser ({ commit }, user) {
      commit('setUserIsLoading', true)
      await axios.put(`users`, user)
        .then(res => {
          console.log(res.data.data)
          commit('saveUpdatedUser', res.data.data)
        }).catch(err => {
          console.log(err)
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updatePassword ({ commit }, user) {
      commit('setUserIsLoading', true)
      await axios.put(`updatePassword`, user)
        .then(res => {
          commit('setUserDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },

    async updateAvatar ({ commit }, formData) {
      commit('setUserIsLoading', true)
      await axios.post(`updateAvatar`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
          Cookie.setAvatar(res.data.data.avatar)
          commit('setUserDetail', res.data.data)
        }).catch(err => {
          const msgMsg = err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
        })
    },


    async deleteUser ({ commit }, id) {
      commit('setUserIsDeleting', true)
      await axios.delete(`users/${id}`)
        .then(res => {
          commit('setDeleteUser', id)
          commit('setUserIsDeleting', false)
        }).catch(err => {
          const msgMsg = err?.response?.data?.error || err?.response?.data?.message || 'Ops! Alguma coisa deu errado.'
          commit('setMsg', msgMsg)
          commit('setMsg_color', 'red')
          commit('setUserIsDeleting', false)
        })
    },

    updateUserInput ({ commit }, e) {
      commit('setUserDetailInput', e)
    },

    clearUser ({ commit }) {
      const clearUser = {'name': null, 'email': null, 'id': null, 'avatar': null, 'company_id': null}
      commit('setUserDetail', clearUser)
    },

    closeMessage ({ commit }) {
      commit('setUserIsLoading', false)
    }
  },

  mutations: {
    setUsers: (state, users) => {
      state.users = users
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setUserDetail: (state, user) => {
      state.user = user
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setDeleteUser: (state, id) => {
      state.users.data = state.users.data.filter(field => field.id !== id)
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setUserDetailInput: (state, e) => {
      const user = state.user
      user[e.target.name] = e.target.value
      state.user = user
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveNewUsers: (state, user) => {
      state.users.data.unshift(user)
      state.createdData = user
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    saveUpdatedUser: (state, user) => {
      const idx = state.users.data.findIndex(o => o.id === user.id)
      if (idx >= 0) {
        state.users.data[idx] = user
      }
      state.updatedData = user
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setUserIsLoading (state, isLoading) {
      state.isLoading = isLoading
      state.msg = null
      state.msg_color = null
    },

    setUserIsDeleting (state, isDeleting) {
      state.isDeleting = isDeleting
      state.isLoading = false
      state.msg = null
      state.msg_color = null
    },

    setMsg(state, data) {
      const xMessage = []
      let erro
      if (typeof (data) === 'object') {
        for (erro in data) {
          xMessage.push(data[erro][0])
        }
        state.msg = xMessage
      } else {
        state.msg = data
      }
      state.isLoading = false
    },

    setMsg_color(state, data) {
      state.msg_color = data
      state.isLoading = false
    }

  }
}
